@include colorBase();

$main-theme-color: #2A5774;	// benx: #174468;
$main-theme-gradient-color: linear-gradient(179deg, #00928E -13%, rgba(255, 255, 255, 0) 123%), #004D9B;
$invite-others-link: #fff;
$todo-content-filter-input: #2a5774 ;
$btn-link-color:  #fff;
$btn-link-color-hover:  #dedede;
$btn-round-color:  #fff ;
$btn-round-color-hover:  #dedede;
$main-background-color-new: $main-theme-gradient-color;
$main-background-hover: #457F99;

// Icon
$start-sprint-icon-color: #7698a4 ;

// Button 
$add-comment-btn: #4283ad;

// Placeholder Color 
$dynamic-theme-placeholder-color:  rgba(255, 255, 255, 0.415);
$dynamic-theme-placeholder-date: white;

// Toggle
$toggle-color: #333;
$toggle-hover: rgba(211,211,211,0.5);
$toggle-active: #b2c8d4;
$toggle-select: #adbec6;
$toggle-hover-opacity: rgba(173, 190, 198,0.5);


// Tab 
$tab-color: #fff;
$tab-active: #fff;
$tab-background-active: #195482;
$tab-background-hover: #206aa4;


// Dynamic
$dynamic-thead-color: white;
$dynamic-thead-background: #2a5774;
$dynamic-thead-background-active: #4c7d9c;
$dynamic-dropdown-menu-active: $main-theme-color;
$dynamic-icon-btn-color: #d3d3d3;
$dynamic-icon-btn-hover: #01384e;
$dynamic-dd-list-bottom-background: #005698;
$dynamic-link-hover-color: #fff;

// Datepicker
$theme-datepicker-color:#286090;
$theme-datepicker-hover: #204d74;

$datepicker-active: $theme-datepicker-color;
$datepicker-hover: $theme-datepicker-hover;
$datepicker-day-active: $theme-datepicker-color;
$datepicker-day-hover: $theme-datepicker-hover;



// Label 
// $label-on: #7fafc5 ;
// $label-on-color: #a22323;



// Modal 
$modal-information-header-bg: $main-theme-gradient-color; 
$modal-information-header-color: #fff ;
$information-header-bg: #2E5774;

// Modal Main System Operation 
$modal-header-bg: $main-theme-gradient-color;
$modal-header-color: #fff;
$modal-nav-option-bg: #e6e6e6 ;
$modal-nav-option-hover: #2a5774;
$modal-nav-option-active: #75848d;
$modal-nav-option-color: #333; 


// Nav  
$navBarColor: #01384E ; 
$navSidebarColor: $main-theme-color;
$navBar-selected: $main-theme-color;  
$nav-sidebar-background-hover:  $main-theme-gradient-color;
$nav-sidebar-background-seleted: $main-theme-gradient-color;
$nav-sidebar-icon-color:  $white; 
$nav-sidebar-icon-hover: $white;

$nav-member-title-background: $main-theme-gradient-color;
$nav-member-title-color:  $white;

// Profile
$profile-nav-tabs-color: #206aa4; //#337ab7;
$profile-nav-tabs-hover: $profile-nav-tabs-color;
$profile-nav-tabs-active: darken($profile-nav-tabs-color, 8%);
$profile-nav-tabs-active-color: #fff;


// SideBar
$notice-header-background: $main-theme-color;
$notice-header-color: $white;


$notice-filter-background: $main-theme-color;
$notice-filter-mobile-background: $main-theme-color;
$notice-filter-mobile-color: #fff;
$notice-filter-color: $white;

$notice-main-background:  #01384E; 
$notice-mobile-background:  #01384E; 
$notice-main-color: $white;

$notice-card-background: $main-theme-color;
$notice-card-background-hover: #30444c;
$notice-card-color: $white;

$notice-tab-background: #5c7f8b;
$notice-tab-active: #01384e;
$notice-tab-hover: #001924;

$projectSetting-menu-header-bg: $main-theme-color;
$projectSetting-menu-header-color: $white;
$projectSetting-content-bg: #01384E; 
$projectSetting-content-color: $white;
$projectSetting-input-color: $white;
$projectSetting-filter-border: #2a5774; 
$projectSetting-filter-bg: transparent; 
$projectSetting-filter-color: $white;
$projectSetting-filter-input: $white;

$calendar-backgound: rgba(117,180,208,0.4);

$filter-block-dropdown-open: #012736;
$filter-block-background: darken($navSidebarColor, 15%);


// MobileMenu Panel
$mobileMenu-main: $main-theme-gradient-color;
$mobileMenu-panel-project-background: #cee8ef ;
$mobileMenu-panel-todo-background: #fcf1be ;
$mobileMenu-panel-backlog-background: #efd9f4 ;
$mobileMenu-panel-review-background: #f9c9c7  ;
$mobileMenu-panel-effort-background: ghostwhite ;
$mobileMenu-panel-managed-background: ghostwhite ;

$otis-card-background: rgb(227, 227, 227);
$otis-card-body-background: rgba(255, 255, 255, 0.8);

// Todo
$todo-nav-option-bg: #174468;
$todo-nav-option-hover: darken($todo-nav-option-bg, 5%);
$todo-nav-option-active: darken($todo-nav-option-bg, 8%);
$todo-nav-option-color: #fff; 
       
$todoCommentEditorBackground: rgba(100,100,100,1);
// Comment
$comment-editor-background: #184569; // lighten($todo-nav-option-bg, 1%); //#75848d;
$system-operation-comment-background: $comment-editor-background;


// Backlog 
$filter-factor-color: rgba(255, 255, 255, 0.3);
$filter-factor-selected-background: rgba(0,0,0,0.2);
$filter-factor-selected-color: rgba(255, 255, 255, 1);
$backlog-page-title-toggle: #0998a0;
$backlog-page-title-active: #0a7076;

$table-sort-indicator-active: #fff;

// File View
$fileView-hover-background: lighten(#cce8ff, 3%);
$folder-row-selected: #619dce ;
$folder-row-hover: #61aaca ;
$folder-row-active: #1f485a ;
$folder-row-active-color: #fff;
$edit-icon-hover: $toggle-hover;
$edit-icon-active: $toggle-active;

$fileView-filter-hover: #75bad0;
$fileView-filter-color:  $toggle-color;


// Discussion
$discussion-panel-selected-background: #5b89a7;
$discussion-panel-selected-color: $white;
// $discussion-panel-background-hover: #2a5774;
$discussion-panel-background-hover: #eee;

$discussion-panel-color-hover: #333;

$discussion-topic-odd-panel: #d4e8f0; 
$discussion-topic-event-panel: #e7f2f7; 

$discussion-sel-topic-title-left: $main-theme-color;
$discussion-sel-topic-title: #3885a6;

$discussion-topic-add-button: $btn-primary;
$discussion-topic-add-button-border: $btn-primary-border;

$discussion-comment-editor-info: #fff;
$cmt-editor-btn-color: #fff;
$info-assignment-color: #fff;
$edit-comment-color: #fff;
$modal-editing-cmt-info-color: #333;


// List View
$list-filter-toggle-hover: rgba(37,87,109,0.4);


// ================== Old color ============== //

$mainColor: $warm;
$main-text:rgb(105,105,105);
$main-text-hover:rgb(38,170,226);
$overlay-color: rgba(20,20,20,0.9);
$dropDrown-color:$warm;
$projectCardHover-background:	#cddcee;
$projectCardWatchIcon-color: #afcde6;
$sprintTitleOpition-hoverColor: $overlay-color;


$main-background-color: darken(rgb(77,142,171), 0%);
//$main-background-color: $calm;
$main-background-focus:darken($main-background-color,5%);
$even-background-color: lighten($main-background-color, 50%);
$odd-background-color:  lighten($main-background-color, 55%);
$addSprint-text-color: #fff; 
$noticeBackground: rgb(63,148,185);
$noticeCardBackground:rgb(56,99,117);
$myTodoListHeaderColor:rgb(19,85,137);

$fileViewHover-border-color: darken(#cce8ff, 15%);
$fileView-selected-background: #cce8ff;


$header-text:white;
$projectBodyBackground: lighten(rgb(42, 87, 116),10%);
$viewTitleBackground: lighten(rgb(42, 87, 116),10%);
$viewTitleTextColor: white;
$viewTitleTextHovered: gold;
$inverseViewTitleTextColor: $warm;

$addProjectCardColor:lighten($calm, 20%);
$recentProjectCardColor:lighten($cool, 20%);
$joinedProjectCardColor:  lighten($calm, 20%);
$archivedProjectCardColor:  lighten($main-text, 0%);

$activeSprintColor:darken(rgb(188,202,147), 0%);
$generalSprintColor: lighten($main-text, 25%);
$finishedSprintColor: rgb(172, 172, 168);
$uncalssifiedBacklogColor: $warm;
$projectTplBackground: $calm ;

$doingSprintColor: darken( rgb(255,205,106), 20%);
$sprintViewBackground: rgba(255,255,255,0.23);
$sprintDetailColor: darken(rgb(188,202,147), 0%);
$doingTodoColor : rgb(255,222,118);
$memberViewColor: lighten($warm, 15%);
$memberViewText-hover:#fff;


$memberViewBacklogColor: lighten($memberViewColor, 15%);

$unStartSprintLight: 5%;
$unStartSprintDark: 0%;
$phaseViewBottomPercent : 10%;
$memberViewBottomPercent: 5%;
$memberViewTodoCardTextColor: $viewTitleTextColor;

$todoListBorderStyle: 1px solid transparent;
$waitTodoListBkColor: lighten($activeSprintColor,10%);
$waitTodoListBkColorHeader: lighten($activeSprintColor,10%);
$waitTodoListBkColorBottom: lighten($activeSprintColor,0%);
$waitTodoListBkColorContent: lighten($waitTodoListBkColor,100%);
$waitTodoListTextColor: $warm;

$doingTodoListBkColor:lighten($doingSprintColor,15%);
$doingTodoListBkColorHeader:  lighten($doingTodoListBkColor,15%);
$doingTodoListBkColorBottom: lighten($doingTodoListBkColor,5%);
$doingTodoListBkColorContent: lighten($doingTodoListBkColor, 100%);
$doingTodoListTextColor: $warm;

$haveDoneListBkColor: gray;
$haveDoneListBkColorHeader: darken($haveDoneListBkColor, 10%);
$haveDoneListBkColorBottom:	lighten($haveDoneListBkColor,15%);
$haveDoneListBkColorContent: lighten($haveDoneListBkColor,10%);
$haveDoneListTextColor:  lighten($main-text, 60%);

$titleBarColor: darken(rgba(230,230,230,1), 5%);
$listHeaderGeneral: $titleBarColor;
$otisLeadColor: #5180B7;
$myselfIconBkColor: #8EB2D6;
$memberIconBkColor: #306A99;
$clientIconBkColor: lightskyblue;
$memberColor: lighten($myselfIconBkColor,20%);
$pmColor: gold ;
$plColor: orange;


$body-background: $projectBodyBackground;
$dynamicBackground: rgba($mainColor,0.4);
$dynamicBackgroundTransparent: rgba($mainColor,0.9);
$dynamicBackgroundDarker: rgba($mainColor,0.8);
$dynamicBackgroundSolid: rgba($mainColor,1);
$dynamicLinkColor: #fff;
$dynamicOpacityFactor: 0.96;
$dynamicReportBackground: rgba(0,0,0,0.8);
$dynamicPlaceHolderColor: whitesmoke;
$dynamicTextColor-dark: lightgray;
$dynamicToggledColor: navajowhite;
$dynamicColorGray: lightgray;

//$mainBackgroundColor: rgb(97,99,99);


$backlogOpacityFactor: 40%;

$backgroundImg: "../../assets/img/otis-defined/otisBk2.jpg";
$backgroundImgSprint: "../../assets/img/otis-defined/otisBk1.jpg";
$selectArrow: "../../assets/img/otis-defined/arrow-B.png";
$sprintBackgroundColor: #e1dad0;

$projectInfoActive-background:rgba(65,64,64,0.96);
$projectInfoActive-color:#fff;
$projectInfoActive-background-hover:lightblue;
$projectInfoTab-color:#fff;
$discussionColor: $warm;
$discussionChatColor: lighten(aliceblue, 0%);
$discussionBtnColor: $cool;
$discussionTimeColor:rgba(37,37,37,0.6);
$commentsHyperlinkColor: $warm;

$calendarBorderColor: $main-background-color;
$reportSubTitle: navajowhite;
$reportEffort: lightblue;
$listViewFilterPanelBackground: rgba(0,0,0,0.1);
$backlogViewFilterPanelBackground: rgba( darken( $navSidebarColor, 5%),1 );

.mobileMenu-time {
    color: $header-text;
    opacity: 0.7;
    @media #{$phone}{
        color: #000;
    }
}