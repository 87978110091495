
@import "base/_resolution.scss";
@import "base/_color.scss";
@import "base/_variables.scss";
@import "base/_themeWorkPro.scss";
@import "base/_font.scss";
@import "base/_mixin.scss"; 
@import "base/_function.scss";

@import	"base/_header.scss";
@import	"base/_body.scss";
@import	"base/_footer.scss";
@import	"base/_select.scss";
@import	"base/_overWrite.scss";
@import "base/_animate.scss";

@import	"base/_commonStyle.scss";
@import "base/_toggle.scss";

@import	"component/_layout.scss";
@import	"component/_button.scss";
@import	"component/_panel.scss";
@import	"component/_dragAndDrop.scss";
@import	"component/_modal.scss";
@import	"component/_navbar.scss";
@import	"component/_sidebar.scss";
@import	"component/_dropdown.scss";

@import	"component/_login.scss";
@import	"component/_member.scss";
@import	"component/_todoItem.scss";
@import "component/_latestInfo.scss";
@import	"component/_backlog.scss";
@import	"component/_discussion.scss";
@import	"component/_fileView.scss";
@import	"component/_ganttChart.scss";
@import	"component/_ganttChartNew.scss";
@import	"component/_todoDetails.scss";
@import	"component/_projectAdd.scss";
@import	"component/_projectActivities.scss";
@import	"component/_projectList.scss";
@import	"component/_projectHeader.scss";
@import	"component/_projectInfo.scss";
@import	"component/_template.scss";
@import	"component/_report.scss";
@import	"component/_searchResult.scss";
@import	"component/_tips.scss";
@import	"component/_checkList.scss";
@import	"component/_setting.scss";
@import	"component/_systemSetting.scss";
@import	"component/_systemSettingSidebar.scss";
@import	"component/_comment.scss";
@import	"component/_forgotPassword.scss";
@import	"component/_imagesGallery.scss";
@import	"component/_pace.scss";
@import	"component/_iconTips.scss";
@import	"component/_mediaView.scss";
@import	"component/_projectEdit.scss";
@import	"component/_phaseView.scss";
@import	"component/_memberView.scss";
@import	"component/_reportForm.scss";
@import	"component/_myNotice.scss";
@import	"component/_fullCalendar.scss";
@import "component/_weekPicker.scss";
@import "component/_manual.scss";
@import "component/_label.scss";
@import "component/_listView.scss";
@import "component/_sprint.scss";
@import "component/_mobileMenu.scss";
@import "component/_addNewTodoModal.scss";
@import "component/_attachmentList.scss";
@import "component/_fileHistory.scss";
@import "component/_timesheet.scss";
@import "component/_linkPreview.scss";
@import "component/_fbCustomize.scss";
@import "component/_switch.scss";
@import "component/_myTodo.scss";
@import "component/_weekTodo.scss";
@import "component/_spinner.scss";
@import "component/_simpleMde.scss";

.nav.project-dropdown .project-dropdown-div  {
    ul {
        background: #01384e;
    }

    .project-title-dropdown li.project-list-item  {

        &.selected {
            background: $main-background-color-new;
            .project-name {
                color: #fff;
            }
        }

        .project-name {
            color: #adbec6;
        }
    }
    .searchbox {
        .otis-input input {
            opacity: 0.6;
        }
    }
}
.nav .project-dropdown-div {
	$_dropdown_header_bg: #2a5774;
    li.dropdown-menu-header {
        background: $_dropdown_header_bg;
        color: rgba(255, 255, 255, 0.35) !important;
        // &:hover {
        //     background: #2a5774 !important;
        // }
    }
    
    .project-title-dropdown li {
        border-bottom: 1px solid #2a5574;
        &:hover, &:active {
            background: $_dropdown_header_bg;
        }
    }

	.project-title-dropdown li.project-list-item {
		&:hover, &:active {
            // background: #162d3c;
            background: #206aa4;
        }
	}
}


pop-sprint-switch-dropdown {
    .dropdown.open>.otis-toggle {
        background: #0a7076;
        color: #fff;
    }

    .sprint-manage-div ul.sprint-manage-body {
        li {
            .odd-background {
                background: #fafafa;
                color: #01384e;
            }
            &.selected {
                background: #2a5774;
            }

            &:hover, &:active {
                background: #206aa4;
            }
        }

    }
}

.quick-query-dropdown .dropdown.open {
    color: #fff;
}

.filter-toggle:hover, 
.filter-toggle:active,
.dropdown.open {
    color: #fff;
}
